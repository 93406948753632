<template>
    <el-popover trigger="click" :width="256" ref="pop">
    <template #reference>
      <el-button :class="['fa',modelValue]"></el-button>
    </template>
    <div class="el-icon-picker">
      <div v-for="icon in globalProperties" :class="['fa',`${icon}`]" @click="$emit('update:modelValue', icon) ;close()"></div>
      <!-- <component v-for="icon in globalProperties" :key="icon"
        :class="[icon.name, 'icon', { 'icon-active': icon == modelValue }]"
        :is="icon"
        @click="$emit('update:modelValue', icon.name) ;close()">
      </component> -->
    </div>
  </el-popover>
</template>
<script>
import iconJson from  './icon.json'
export default{
    props:{
        modelValue:''
    },
    emits: ['update:modelValue'],
    data(){
        return{
            globalProperties:null
        }
    },
    mounted(){
        this.globalProperties = JSON.parse(JSON.stringify(iconJson))
    },
    methods:{
      close(){
        this.$refs.pop.hide()
      }
    }
}

</script>
<style lang="less" scoped>
// @import '@/assets/less/font-awesome.less';
.el-icon-picker {
  height: 256px;
  overflow-y: scroll;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  .fa{
    display: inline-block;
    width: 24px;
    height: 24px;
    color: var(--el-text-color-regular);
    font-size: 20px;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    line-height: 45px;
    margin: 5px;
  }
}



.fa:hover {
  color: var(--el-color-primary);
}

.fa-active {
  color: var(--el-color-primary);
}
</style>