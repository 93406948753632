import {Node} from 'butterfly-dag';
import $ from 'jquery'
class BaseNode extends Node{
    constructor(opts){
        super(opts)
        this.options = opts
    }

    draw = (opts)=>{
        let container = $('<div class="relation-node"></div>')
            .css('top', opts.top)
            .css('left', opts.left)
            .attr('id', opts.id)
            .addClass(opts.options.className);
        let title = $(`<div class="title" style="background-color:${opts.options.titleColor != '' ? opts.options.titleColor : '#000'};"><i class="fa ${opts.options.icon}"></i>${opts.options.title}<div>`);
        // let icon = $(`<i class="fa ${opts.options.icon}"></i>`)[0];
        let logoContainer = $(`<div class="content">${opts.options.content}</div>`);
        logoContainer.addClass(opts.options.className);
        container.append(title);
        container.append(logoContainer);
        return container[0];
    }
}
export default BaseNode;