import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const app =  createApp(App)
app.use(store).use(router).use(ElementPlus)
//注册elementIcon组件
for (const [key, val] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, val)
}
app.mount('#app')