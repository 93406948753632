import { createRouter, createWebHistory } from 'vue-router';
import indexView  from '../views/index/index.vue';
const routes = [
  {
    path: '/',
    name: 'index',
    component: indexView
  },
  {
    path:"/login",
    name:'login',
    component: ()=> import(/* webpackChunkName:"login" */ '../views/login/login.vue'),
  }
]

const router = createRouter({
  mode:'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// console.log(process.env.BASE_URL)
export default router
