import baseNode from './baseNode'


export default{
    nodes:[
        {
            id:0,
            title:'初始节点',
            titleColor:"#f70",
            content:'节点内容说明',
            Class :baseNode,
            icon:'fa-circle',
            endpoints:[
                {
                    id: '1-1',
                    orientation: [0, 1],
                    pos: [0.5, 1]
                },
            ]
        }
    ]
}