
<template>
    <div class="container">
        <el-container>
            <el-main class="main">
                <div class="canvas" ref="canvas"></div>
                <el-button class="downLoadIcon" @click="saveImg()" type="primary">
                    <el-icon><Picture /></el-icon>
                </el-button>
                
            </el-main>
            <el-aside class="aside">
                <!-- <el-row>
                    <el-col>
                        <el-button type="primary">添加节点</el-button>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        
                    </el-col>
                </el-row> -->
                <el-form label-width="75px" :rules="rules">
                    <el-form-item label="节点标题" prop="title">
                        <el-input v-model="nodeForm.title" />
                    </el-form-item>
                    <el-form-item label="节点描述" prop="content">
                        <el-input v-model="nodeForm.content" />
                    </el-form-item>
                    <el-form-item label="图标/背景" prop="icon">
                        <!-- <el-input v-model="nodeForm.name" /> -->
                        <iconPicker v-model="nodeForm.icon"></iconPicker>
                        <el-color-picker class="colorPicker" v-model="nodeForm.titleColor" />
                    </el-form-item>
                    <el-form-item label="连接点" >
                        <el-checkbox-group v-model="nodeForm.endpoints" size="large">
                            <el-checkbox-button label="up">
                                <i class="fa fa-angle-up"></i>
                            </el-checkbox-button>
                            <el-checkbox-button label="down">
                                <i class="fa fa-angle-down"></i>
                            </el-checkbox-button>
                            <el-checkbox-button label="left">
                                <i class="fa fa-angle-left"></i>
                            </el-checkbox-button>
                            <el-checkbox-button label="right">
                                <i class="fa fa-angle-right"></i>
                            </el-checkbox-button>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="add()">添加节点</el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="save()">
                            <i class="fa fa-floppy-o" style="margin-right:5px"></i>
                            保存流程
                        </el-button>
                    </el-form-item>
                    
                </el-form>
            </el-aside>
        </el-container>   
    </div>
</template>
<script>
import {Canvas, Group, Node, Edge} from 'butterfly-dag';
import 'butterfly-dag/dist/index.css';
import iconPicker from '@/components/iconPicker/iconPicker'
import nodeData from './data'
import baseNode from './baseNode'
const validat = (res,callback)=>{
 console.log(res)
}
export default{
    components:{
        iconPicker
    },
    data(){
        return{
            canvas:null,
            canvasDom:null,
            nodeForm:{
                title:'',
                content:'',
                icon:'',
                titleColor:'',
                endpoints:[]
            },
            rules:{
                title:[{validator:validat,trigger:'bulr',msg:'请输入节点名称'}],
                icon:[{validator:validat,trigger:'bulr',msg:'请选择图标'}],
                titleColor:[{validator:validat,trigger:'bulr',msg:'请选择颜色'}],
                endpoints:[{validator:validat,trigger:'bulr',msg:'请选择连接点'}]
            },
        }
    },
    mounted(){
        this.initCanvas()
    },
    methods:{
        initCanvas(){
            this.canvasDom = this.$refs.canvas
            this.canvas = new Canvas({
                root: this.canvasDom,              //canvas的根节点(必传)
                disLinkable: true, // 可删除连线
                linkable: true,
                zoomable: true,         //可缩放(可传)
                moveable: true,         //可平移(可传)
                draggable: true,        //节点可拖动(可传)
                theme: {
                    edge: {
                        shapeType: 'Manhattan',
                        arrow: true
                    }
                },
                layout:{
                    type:'compactBox',
                    options:{
                        rankdir:'TB',
                        nodesep:'40',
                        ranksep:'40',
                        controlPoints:false
                    }
                }
            });
            this.canvas.draw({},()=>{
                this.canvas.setGridMode(true, {
                    isAdsorb: false,         // 是否自动吸附,默认关闭
                    theme: {
                    shapeType: 'circle',     // 展示的类型，支持line & circle
                    gap: 20,               // 网格间隙
                    background: 'rgba(50, 50, 50, 0.65)',     // 网格背景颜色
                    circleRadiu: 1.5,        // 圆点半径
                    circleColor: 'rgba(255, 255, 255, 0.8)'    // 圆点颜色
                    }
                })
            })
            this.canvas.draw(nodeData,(res)=>{
                this.canvas.focusCenterWithAnimate();
            });
        },
        add(){
            console.log(this.nodeForm)
            let endpoints = []
            let endpointsObj = {
                down:{
                    id: nodeData.nodes.length + '-1',
                    orientation: [0, 1],
                    pos: [0.5, 1]
                },
                up:{
                    id: nodeData.nodes.length + '-2',
                    orientation: [0, -1],
                    pos: [0.5, 0]
                },
                left:{
                    id: nodeData.nodes.length + '-3',
                    orientation: [1, 0],
                    pos: [0, 0.5]
                },
                right:{
                    id: nodeData.nodes.length + '-4',
                    orientation: [-1, 0],
                    pos: [1, 0.5]
                }
            }
            this.nodeForm.endpoints.map((v)=>{
                endpoints.push(endpointsObj[v])
            })

            let data = {
                id:nodeData.nodes.length,
                top:200,
                lef:200,
                draggable :true,
                title:this.nodeForm.title,
                content:this.nodeForm.content,
                icon:this.nodeForm.icon,
                titleColor:this.nodeForm.titleColor,
                Class:baseNode,
                endpoints:endpoints
            }
            this.canvas.addNode(data)
            nodeData.nodes.push(data)
            this.nodeForm.title =  ''
            this.nodeForm.icon = ''
            this.nodeForm.content = ''
            this.nodeForm.endpoints = []
        },
        getNodes(){

        },
        save(){
            let dataMap = this.canvas.getDataMap()
            console.log(dataMap)
        },
        saveImg(){
            this.canvas.save2img({
                type:'png',
                quality:1
            }).then((dataUrl)=>{
                let link = document.createElement('a');
                link.download = 'XXX.png';
                link.href = dataUrl;
                link.click();
            })
        }
        
    }
}
</script>
<style lang="less" scoped>
@import './index.less';
@import './node.less';
</style>